<template>
    <div class="services">
        <h1>Check out our 2023 Menu of Services!</h1>
        <div class="subjects-wrapper">
            <ol class="main-indent"><p><strong>Services Offered:</strong> (6-11th grade)</p>
                <li>1:1 Zoom tutoring
                    <ol class="second-indent">
                        <li>The 1:1 student-instructor ratio allows for a personalized, individual tutoring experience</li>
                    </ol>
                </li>
                <li>Group Zoom Tutoring
                    <ol class="second-indent">
                        <li>
                            Group tutoring is capped at 4 students to ensure an effective learning environment. Note that only high-demand subjects will be offered to a group.
                        </li>
                    </ol>
                </li>
            </ol>
            <ol class="main-indent"><p><strong>Subjects offered (available in all levels):</strong></p>
                <li>Math
                    <ol class="second-indent">
                        <li>Algebra I (middle school math)</li>
                        <li>Algebra II</li>
                        <li>Geometry</li>
                        <li>Precalculus</li>
                    </ol>
                </li>
                <li>Science
                    <ol class="second-indent">
                        <li>Biology</li>
                        <li>Chemistry</li>
                        <li>Physics</li>
                    </ol>
                </li>
                <li>Language</li>
                <li>Computer Science</li>
            </ol>
        </div>
        <h3>Note: During the summer, tutoring sessions will focus on preparing students for the next year in the desired subject area. During the school year, we can either pace with the curriculum or preview the units ahead.</h3>
    </div>
</template>

<script>
export default {
  name: 'Services',
  components: {
    
  }
}
</script>

<style scoped>
ol {
    list-style-type: disc;
}
ol.second-indent {
    list-style-type: circle;
}
.subjects-wrapper {
    font-size: 1.25rem;
    margin-bottom: 5px;
    width: 80vw;
    margin: 0 auto;
    padding: 20px;
    border: 2px solid#010314;
    border-radius: 25px;
    text-align: left;
    /* text-transform: lowercase; */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(151, 134, 108, 0.4); /* Black w/opacity/see-through */
}
</style>