<template>
    <div class="about">
        <!-- <h1>This is an about page</h1><br> -->
        <div class="title-wrapper"><h1 class="title">Nowadays there are so many tutors that it is hard to choose the right one. However, we at Excel Tutoring have many advantages over "professional" tutors.</h1></div>
        <div class="content-wrapper">
            <ul>
                <li>
                    <p><strong>Price</strong>: As high school students, we are able to offer tutelage at a fraction of the cost; we believe in quality education without associated high cost.</p>
                </li>
                <li>
                    <p><strong>Quality</strong>: As Wayland High School students, our tutors took the same classes your child is taking now. They have in-depth knowledge of the curriculum required to tailor the program to the unique demands of the class. Every Excel tutor goes through a rigorous vetting process, and has been hand-picked as masters in their subject.</p>
                </li>
                <li>
                    <p><strong>Connection</strong>: A growing body of evidence suggests that students are more susceptible to learn when they work with a fellow student. Students spend every week in a classroom with adults, so going over the material with a tutor can help them to better absorb the curriculum.</p>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
  name: 'About',
  components: {
    
  }
}
</script>

<style scoped>
.about {
    margin-bottom: 10vh;
}
.title-wrapper {
    width: 80vw;
    margin: 0 auto;
    padding: 20px;
}
.title {
    margin: 0 auto;
}
.content-wrapper {
    font-size: 1.25rem;
    margin-bottom: 5px;
    width: 80vw;
    margin: 0 auto;
    padding: 20px;
    border: 2px solid#010314;
    border-radius: 25px;
    text-align: left;
    /* text-transform: lowercase; */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(151, 134, 108, 0.4); /* Black w/opacity/see-through */
}
</style>