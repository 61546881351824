<template>
    <div class="contact">
        <!-- <h1>This is a contact page</h1> -->
        <br><br><br>
        <h2>Thank you for your interest in Excel Tutoring. Please click the "sign-up" button, and we will get back to you as soon as possible to schedule a free consultation.</h2>
        <br><br><br>
        <h3>Additionally, contact us at exceltutoringwayland@gmail.com with any questions or concerns!</h3>
    </div>
</template>

<script>
export default {
  name: 'Contact',
  components: {
    
  }
}
</script>

<style scoped>

</style>