<template>
    <div class="tutors">
        <h1>Check out our suite of tutors!</h1>
        <flickity ref="flickity" :options="flickityOptions" class="slider">
            <div class="carousel-cell">
                <div class="cell-content left">
                    <img src="../assets/michael.jpg" alt="michael" class="image-michael">
                </div>
                <div class="cell-content right">
                    <h3>Michael Wightman</h3>
                    <p>Michael is one of the founders of Excel Tutoring. In addition to tutoring with Deluxe Thinkers, he is a Captain of the Wayland Debate Team and Model UN Club. Through all of this, he has gained experience about how to teach subjects in engaging ways, tailored to the unique learning styles of different students. In addition to all of the core subjects, he has taken the SAT and the AP Chemistry, AP US History, and AP Computer Science A classes. In his free time, he likes to ski, run, and cook.</p>
                </div>
            </div>
            <div class="carousel-cell">
                <div class="cell-content left">
                    <img src="../assets/brendan.jpg" alt="brendan" class="image-brendan">
                </div>
                <div class="cell-content right">
                    <h3>Brendan Shen</h3>
                    <p>Brendan is one of the founders of Excel Tutoring. He has been helping classmates learn material since middle school through tutoring; he is the go-to person to ask for help in all of his classes. In his four years of tutoring, he has worked with many organizations such as Deluxe Thinkers and Khan Academy. He has also tutored many subjects such as SAT Math. In his free time, he likes to read, code, and play soccer.</p>
                </div>
            </div>
            <div class="carousel-cell">
                <div class="cell-content left">
                    <img src="../assets/charmaine.jpg" alt="charmaine" class="image-charmaine">
                </div>
                <div class="cell-content right">
                    <h3>Charmaine Guo</h3>
                    <p>Charmaine is a Junior who worked with Study Buddies during the pandemic, keeping her tutee engaged in and excited about her lessons. Using her synchronized swimming experience, Charmaine also teaches swimming by evaluating the pace and materials every child needs to succeed. Driven and focused, she has continued to excel in many honors and AP classes such as AP Chemistry, AP Environmental Science Honors Math, and Honors Physics. In her free time, she enjoys sewing, baking, and competing on Wayland High School’s robotics team.</p>
                </div>
            </div>
            <div class="carousel-cell">
                <div class="cell-content left">
                    <img src="../assets/thomas.png" alt="thomas" class="image-thomas">
                </div>
                <div class="cell-content right">
                    <h3>Thomas Kiernan</h3>
                    <p>Tom has been a tutor for three years, having worked with both Middle School and High School students. Tom has a portfolio of tutoring in various types of math and science across different levels. He is passionate about math and computer science and enjoys using his skills to help other people learn more about these subjects. In his free time, he enjoys coding and playing basketball.</p>
                </div>
            </div>
            <!-- <div class="carousel-cell">3</div> -->
            </flickity>
    </div>
    
</template>

<script>
import Flickity from 'vue-flickity'

export default {
  name: 'Tutors',
  components: {
      Flickity,
    },
    data() {
      return {
        flickityOptions: {
          initialIndex: 0,
          prevNextButtons: true,
          pageDots: false,
          autoPlay: false,
          freeScroll: false,
          wrapAround: true,
          // arrowShape: "M 0,50 L 60,00 L 50,30 L 80,30 L 80,70 L 50,70 L 60,100 Z",
        },
      };
    },
    methods: {
    next() {
      this.$refs.flickity.next();
    },
    previous() {
      this.$refs.flickity.previous();
    },
  },
}
</script>

<style scoped>
.tutors {
    background: transparent;
}
.content-wrapper {
    font-size: 1.25rem;
    margin-bottom: 5px;
    width: 80vw;
    margin: 0 auto;
    padding: 20px;
    border: 2px solid#010314;
    border-radius: 25px;
    text-align: left;
    /* text-transform: lowercase; */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(151, 134, 108, 0.4); /* Black w/opacity/see-through */
}
.slider {
    padding: 100px;
    
}
.carousel-cell {
    font-size: 1.25rem;
    width: 80vw;
    height: 40vh;
    padding: 20px;
    margin: 0 auto;
    border: 1px solid#010314;
    border-radius: 25px;
    text-align: left;
    vertical-align: middle;
    display: flex;
    align-items: center;
    /* text-transform: lowercase; */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(151, 134, 108, 0.4); /* Black w/opacity/see-through */
}
.image-brendan {
    max-width: 40%;
    max-height: 40%;
    display: block; /* remove extra space below image */
    border-radius: 25px;
    margin: 0 auto;
}
.image-michael {
    max-width: 50%;
    max-height: 50%;
    display: block; /* remove extra space below image */
    border-radius: 25px;
    margin: 0 auto;
}
.image-charmaine {
    max-width: 50%;
    max-height: 50%;
    display: block; /* remove extra space below image */
    border-radius: 25px;
    margin: 0 auto;
}
.image-thomas {
    max-width: 50%;
    max-height: 50%;
    display: block; /* remove extra space below image */
    border-radius: 25px;
    margin: 0 auto;
}
.cell-content.right {
    width: 70%;
    float: left;
}
.cell-content.left{
    width: 30%; 
    float:left;
    display: flex;
    align-items: center;
}
</style>