<template>
  <div class="home">
    <!-- <img alt="Vue logo" src="../assets/logo.png"> -->
    <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
    <h1>Excel with us, one step at a time!</h1>
    <img src="../assets/zoom-tutoring.png" alt="tutoring" class="home-picture">
    <h3>Here at Excel Tutoring Wayland, we value student connection with tutors in order to create a strong learning environment that leads to success.</h3>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'Home',
  components: {
    
  }
}
</script>

<style scoped>
.home-picture {
  height: 50%;
  width: 50%;
}
@media only screen and (min-width:768px) {
  .home-picture {
    height: 25%;
    width: 25%;
  }
}
</style>