<template>
  <div id="app" class="theme">
    <div class="main-container">
      <div class="navbar">
        <ul>
          <div class="floatleft">
            <li>
              <router-link to="/">Home</router-link>
            </li>
            <li>
              <router-link to="/about">About</router-link>
            </li>
            <li>
              <router-link to="/tutors">Tutors</router-link>
            </li>
            <li>
              <router-link to="/services">Services</router-link>
            </li>
            <li>
              <router-link to="/contact">Contact</router-link>
            </li>
          </div>
          <div class="floatright">
            <li>
              <a href="https://5978pzcslme.typeform.com/to/r7isroiw" target="blank" class="sign-up">
                <button class="nav-element">
                  Sign up
                </button>
              </a>
            </li>
          </div>
        </ul>
      </div>
      
      <div class="slide">
        <Slide >
          <li class="hamburger-item">
            <router-link to="/">Home</router-link>
          </li>
          <li class="hamburger-item">
            <router-link to="/about">About</router-link>
          </li>
          <li class="hamburger-item">
            <router-link to="/tutors">Tutors</router-link>
          </li>
          <li class="hamburger-item">
            <router-link to="/services">Services</router-link>
          </li>
          <li class="hamburger-item">
            <router-link to="/contact">Contact</router-link>
          </li>
        </Slide>
      </div>
      <div>
        <a href="https://5978pzcslme.typeform.com/to/r7isroiw" target="blank" class="sign-up">
          <button class="sign-up2">
            Sign up
          </button>
        </a>
      </div>

      <div class="image-wrapper">
        <img
          src="./assets/ET.png"
          alt="logo"
          class="logo"
        />
      </div>
      <br><br>
      <body>
        <transition name="fade" mode="out-in"><router-view></router-view></transition>
      </body>
    </div>
    <footer>
      <p>Excel Tutoring Wayland 2023</p>
    </footer>
  </div>
</template>

<script>
import { Slide } from 'vue3-burger-menu'  // import the CSS transitions you wish to use, in this case we are using `Slide`

export default {
    components: {
        Slide // Register your component
    }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100&family=Square+Peg&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@200;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@200;500&family=Roboto&display=swap');

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.75s ease;
}

.fade-enter-from,
.fade-leave-active {
  opacity: 0;
}

body {
  background-color: var(--bg-color);
  margin: 0;
}
body::-webkit-scrollbar {
  display: none;
}
#app {
  font-family: 'Raleway', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #ffffff;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #1e37c9;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: transparent;
  width: 100%;
}

.main-container {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  min-height: 100vh;
  /*the above is*/
  color: var(--font-color);
  background-color: var(--bg-color);
  /* transition: background-color 2s, color 2s; */
}

.navbar {
  background-image: linear-gradient(var(--top-gradient), var(--bottom-gradient));
  width: auto;
  transition: all 1s;
}

.nav-element {
  vertical-align: middle;
}

.navbar li {
  color: var(--font-color);
  font-size: calc(12px + 0.5vw);
  height: 100%;
  padding: 10px;
}
.navbar li a {
  float: left;
  color: var(--font-color);
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  height: 100%;
  transition: all 1s;
}
.navbar li a.sign-up {
  float: left;
  color: var(--font-color);
  text-align: center;
  padding: 0px;
  text-decoration: none;
  height: 100%;
  transition: all 1s;
}
.navbar li a.router-link-exact-active {
  transform: scale(1.2);
  color: var(--link-hover);
}
.navbar li button {
  float: right;
  border-radius: 22px;
  /*background-color: transparent;*/
  text-align: center;
  padding: 14px 16px;
  /* text-decoration: none; */
  border: none;
  cursor: pointer;
  color: var(--font-color);
  font-size: 1.2rem;
  transition: all 1s;
}
.sign-up {
  float: left;
  color: var(--font-color);
  text-align: center;
  padding: 0px 0px;
  text-decoration: none;
  height: 100%;
  transition: all 1s;
  position: relative;
}
.navbar li p {
  float: right;
  vertical-align: text-top;
  margin-right: 20px;
}
.navbar li a:hover {
  color: var(--link-hover);
}
.navbar li button:hover {
  background-color: var(--button-hover);
}
.navbar li a button:hover {
  color: var(--button-link-hover);
}
.navbar img {
  vertical-align: text-top;
  transition: all 1s;
}
.navbar img:hover {
  opacity: 0.6;
  transform: scale(1.1);
}

.floatleft {
  float: left;
  display: flex;
  align-items: center;
}
.floatright {
  float: right;
  display: flex;
  align-items: center;
}
.logo {
  margin: 0 auto;
  width: auto;
  height: auto;
}

.slide {
  display: none;
}
.sign-up2 {
  display: none;
}
.image-wrapper {
  margin: 0 auto;
  width: 80vw;
  background: transparent;
}

footer {
  background-color: var(--footer);
  /* padding: 3vw; */
  padding-top: 2vh;
  padding-bottom: 2vh;
  height: 15%;
  width: 100%;
  transition: all 2s;
}
.theme {
  --top-gradient: #DDD0C8;
  --bottom-gradient: #DDD0C8;
  --bg-color: #DDD0C8;
  --font-color: #323232;
  --font-color2: #ddd5c6;
  --link-hover: #968d58;
  --button-hover: #a39a94;
  --button-link-hover: #e7dfda;
  --footer: black;
}

@media only screen and (max-width:768px) {
  .sign-up2 {
    display: block;
    position: fixed;
    width: 20%;
    height: 3%;
    left: 75%;
    top: 2%;
    cursor: pointer;
    border-radius: 22px;
    /*background-color: transparent;*/
    text-align: center;
    /* text-decoration: none; */
    border: none;
    color: var(--font-color);
    transition: all 1s;
  }
  .slide {
    display: block;
  }
  .slide li {
    color: var(--font-color2);
    font-size: calc(12px + 0.5vw);
    height: 100%;
    padding: 10px;
  }
  .slide li a {
    float: left;
    color: var(--font-color2);
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    height: 100%;
    transition: all 1s;
  }
  .bm-burger-button {
    position: fixed;
    width: 7.5%;
    height: 3%;
    left: 2%;
    top: 2%;
    cursor: pointer;
  }
  .bm-burger-bars {
    background-color: #373a47;
  }
  .line-style {
    position: absolute;
    height: 20%;
    left: 0;
    right: 0;
  }
  .cross-style {
    position: absolute;
    top: 12px;
    right: 2px;
    cursor: pointer;
  }
  .bm-cross {
    background: #bdc3c7;
  }
  .bm-cross-button {
    height: 24px;
    width: 24px;
  }
  .bm-menu {
    height: 100%; /* 100% Full-height */
    width: 0; /* 0 width - change this with JavaScript */
    position: fixed; /* Stay in place */
    z-index: 1000; /* Stay on top */
    top: 0;
    left: 0;
    background-color: rgb(63, 63, 65); /* Black*/
    overflow-x: hidden; /* Disable horizontal scroll */
    padding-top: 60px; /* Place content 60px from the top */
    transition: 0.5s; /*0.5 second transition effect to slide in the sidenav*/
  }

  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
  }
  .bm-item-list {
    color: #b8b7ad;
    margin-left: 10%;
    font-size: 20px;
  }
  .bm-item-list > * {
    display: flex;
    text-decoration: none;
    padding: 0.7em;
  }
  .bm-item-list > * > span {
    margin-left: 10px;
    font-weight: 700;
    color: white;
  }

  .navbar {
    background-image: linear-gradient(var(--top-gradient), var(--bottom-gradient));
    width: auto;
    transition: all 1s;
    display: none;
  }
  
  .nav-element {
    vertical-align: middle;
  }
  
  .navbar li {
    color: var(--font-color);
    font-size: calc(12px + 0.5vw);
    height: 100%;
    width: auto;
    padding: 10px;
  }
  .navbar li a {
    float: left;
    color: var(--font-color);
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    height: 100%;
    transition: all 1s;
  }
  .navbar li a.sign-up {
    float: left;
    color: var(--font-color);
    text-align: center;
    padding: 0px;
    text-decoration: none;
    height: 100%;
    transition: all 1s;
  }
  .navbar li a.router-link-exact-active {
    transform: scale(1.2);
    color: var(--link-hover);
  }
  .navbar li button {
    float: right;
    border-radius: 22px;
    /*background-color: transparent;*/
    text-align: center;
    padding: 14px 16px;
    /* text-decoration: none; */
    border: none;
    cursor: pointer;
    color: var(--font-color);
    font-size: 1.2rem;
    transition: all 1s;
  }
  .sign-up {
    float: left;
    color: var(--font-color);
    text-align: center;
    padding: 0px 0px;
    text-decoration: none;
    height: 100%;
    transition: all 1s;
    position: relative;
  }
  .navbar li p {
    float: right;
    vertical-align: text-top;
    margin-right: 20px;
  }
  .navbar li a:hover {
    color: var(--link-hover);
  }
  .navbar li button:hover {
    background-color: var(--button-hover);
  }
  .navbar li a button:hover {
    color: var(--button-link-hover);
  }
  .navbar img {
    vertical-align: text-top;
    transition: all 1s;
  }
  .navbar img:hover {
    opacity: 0.6;
    transform: scale(1.1);
  }
  
  .floatleft {
    float: left;
    display: flex;
    align-items: center;
  }
  .floatright {
    float: right;
    display: flex;
    align-items: center;
  }
  .logo {
    margin: 0 auto;
    width: 100%;
    height: auto;
  }
  .image-wrapper {
    margin-top: 50px;
    margin: 0 auto;
    width: 80vw;
    background: transparent;
  }
}


</style>
